import { Injectable } from '@angular/core';
import { AdminLogin } from '../../guest/_models/admin-login.model';

import { Collection } from '../../dashboard/_models/collection.model';
import { Customer } from '../../dashboard/customer/_models/customer.model';
import { Faq } from '../../dashboard/faq/_models/faq.model';
import { InspireQuote } from '../../dashboard/inspire-quote/_models/inspire-quote.model';
import { CustomerUpdate } from '../../dashboard/customer/_models/customer-update.model';
import { Coupon } from '../../dashboard/coupon/_models/coupon.model';
import { ExtraPhoto } from '../../dashboard/extra-photo/_models/extra-photo.model';
import { GiftcardProduct } from '../../dashboard/giftcard-product/_models/giftcard-product.model';
import { CouponOrder } from '../../dashboard/coupon-order/_models/coupon-order.model';

@Injectable({
    providedIn: 'root'
})
export class DataService {

    private customer: Customer;
    private faq: Faq;
    private inspireQuote: InspireQuote;
    private coupon: Coupon;
    private couponOrder: CouponOrder;
    private extraPhoto: ExtraPhoto;
    private giftcardProduct: GiftcardProduct;
    private warning = [];
    private statusText: string;

    public paymentStatusCollection: Collection[] = [];
    public orderStatusCollection: Collection[] = [];
    public customerStatusCollection: Collection[] = [];
    public priorityStatusCollection: Collection[] = [];
    public statusCollection: Collection[] = [];
    public administratorCollection: Collection[] = [];

    constructor(
    ) {
        this.reset();
    }

    public reset() {
        this.customer = null;
    }

    public getCurrentUser(): AdminLogin {
        return JSON.parse(localStorage.getItem('currentUser'));
    }

    public hasCurrentUser(): boolean {
        if (localStorage.getItem('currentUser')) {
            return true;
        }
        return false;
    }

    public getCustomer(): Customer {
        return this.customer;
    }

    public setCustomer(customer: Customer): void {
        this.customer = customer;
    }

    public getFaq(): Faq {
        return this.faq;
    }

    public setFaq(faq: Faq): void {
        this.faq = faq;
    }

    public getInspireQuote(): InspireQuote {
        return this.inspireQuote;
    }

    public setInspireQuote(inspireQuote: InspireQuote): void {
        this.inspireQuote = inspireQuote;
    }

    public setWarning(obj: any) {
        this.warning = obj;
    }

    public getWarning() {
        return this.warning;
    }

    public setStatusText(text: string) {
        this.statusText = text;
    }

    public getStatusText(): string {
        return this.statusText;
    }

    public getCoupon(): Coupon {
        return this.coupon;
    }

    public setCoupon(coupon: Coupon) {
        this.coupon = coupon;
    }

    public getCouponOrder(): CouponOrder {
        return this.couponOrder;
    }

    public setCouponOrder(couponOrder: CouponOrder) {
        this.couponOrder = couponOrder;
    }

    public getExtraPhoto(): ExtraPhoto {
        return this.extraPhoto;
    }

    public setExtraPhoto(extraPhoto: ExtraPhoto) {
        this.extraPhoto = extraPhoto;
    }

    public getGiftcardProduct(): GiftcardProduct {
        return this.giftcardProduct;
    }

    public setGiftcardProduct(giftcardProduct: GiftcardProduct) {
        this.giftcardProduct = giftcardProduct;
    }
}