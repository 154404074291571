import {ApiError} from './api-error.model';

export class ApiResponse<T extends any> {
    public self: string;
    public total: number | null;
    public result: T[];
    public errors: ApiError[];

    public hasErrors(): boolean {
        return this.errors.length > 0;
    }

    public hasResult(): boolean {
        return this.result.length > 0;
    }

    public singleResult(): T {
        return this.result[0];
    }

    public create(response: ApiResponse<T> ) {
        this.self = response.self;
        this.total = response.total;
        this.result = response.result;
        this.errors = response.errors;
    }
}