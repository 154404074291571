import { Router } from '@angular/router';
import { HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { ApiResponse } from '../../_models/api-response.model';

export class BaseHttpService {
    constructor(
        protected router: Router
    ) { }

    protected getFullUrl(partialUrl: string): string {
        let full = environment.baseUrl + partialUrl;
        return full;
    }

    protected addTokenToHeader(headers: HttpHeaders) {
        let currentUser = JSON.parse(localStorage.getItem('currentUser'));
        if (currentUser && currentUser.token) {
            return headers.append('Authorization', 'Bearer ' + currentUser.token);
        }
    }

    protected extractData<T>(res: ApiResponse<T>): ApiResponse<T> {
        let body = new ApiResponse<T>();
        body.create(res);

        if (body.hasErrors()) {
            this.onError(res);
        } else {
            if (!environment.production) {
                console.log('success:');
                console.log(body);
            }
        }

        return body;
    }

    protected onError<T>(res: ApiResponse<T>) {
        let invalidLogin = res.errors.some(x => x.code === 'BRSS_4011');
        if (invalidLogin) {
            this.router.navigate(['/login'], { queryParams: { returnUrl: '/' } });
        }

        if (!environment.production) {
            console.log('errors:');
            console.log(res);
        }
    }
}