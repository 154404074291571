import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { ApiResponse } from '../shared/_models/api-response.model';
import { BaseHttpService } from './_base/base-http.service';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators'

@Injectable({
    providedIn: 'root'
})
export class JsonHttpService extends BaseHttpService {
    constructor(
        private http: HttpClient,
        router: Router
    ) {
        super(router);
    }

    getMany<TReturn>(functieUrl: string) {
        return this.http.get(this.getFullUrl(functieUrl), this.jwt())
        .pipe(
            map((response: ApiResponse<TReturn>) => this.extractData<TReturn>(response)),
            catchError((response: ApiResponse<TReturn>) => of(this.extractData<TReturn>(response))) 
        );  
    }

    get<TReturn>(functieUrl: string) {
        return this.http.get(this.getFullUrl(functieUrl), this.jwt())
        .pipe(
            map((response: ApiResponse<TReturn>) => this.extractData<TReturn>(response)),
            catchError((response: ApiResponse<TReturn>) => of(this.extractData<TReturn>(response))) 
        );  
    };

    post<TPost, TReturn>(functieUrl: string, body: TPost) {
        return this.http.post(this.getFullUrl(functieUrl), body, this.jwt())
        .pipe(
            map((response: ApiResponse<TReturn>) => this.extractData<TReturn>(response)),
            catchError((response: ApiResponse<TReturn>) => of(this.extractData<TReturn>(response))) 
        );  
    }

    put<TPut, TReturn>(functieUrl: string, body: TPut) {
        return this.http.put(this.getFullUrl(functieUrl), body, this.jwt())
        .pipe(
            map((response: ApiResponse<TReturn>) => this.extractData<TReturn>(response)),
            catchError((response: ApiResponse<TReturn>) => of(this.extractData<TReturn>(response))) 
        );  
    }

    patch<TPatch, TReturn>(functieUrl: string, body: TPatch) {
        return this.http.patch(this.getFullUrl(functieUrl), body, this.jwt())
        .pipe(
            map((response: ApiResponse<TReturn>) => this.extractData<TReturn>(response)),
            catchError((response: ApiResponse<TReturn>) => of(this.extractData<TReturn>(response))) 
        );  
    }

    delete<TReturn>(functieUrl: string) {
        return this.http.delete(this.getFullUrl(functieUrl), this.jwt())
        .pipe(
            map((response: ApiResponse<TReturn>) => this.extractData<TReturn>(response)),
            catchError((response: ApiResponse<TReturn>) => of(this.extractData<TReturn>(response))) 
        );  
    }

    private jwt() {
        let headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        headers = this.addTokenToHeader(headers);
        return { headers: headers };
    }
}